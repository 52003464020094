import { BranchModel } from '@/modules/branch/branch-model';

const { fields } = BranchModel;

export default [
  fields.name,
  fields.description,
  fields.unitPrice,
  fields.photos,
];
