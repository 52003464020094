import firebase from 'firebase/app'
import 'firebase/firestore'
import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-complaint';
import graphqlClientNotification from '@/shared/graphql/client-notification';

export class complaintService {
  static setComplaintListener(callback1, callback2) {
    const ids = []
    
    firebase.firestore().collection('complaint').orderBy("updatedAt", "desc").onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => {
        ids.push(doc.id)
        const data = {
          ...doc.data(),
          id: doc.id
        }
        callback1(data)
      })
      this.setMessageListener(ids, callback2)
    })
  }
  static async setMessageListener(ids, callback) {
    ids.forEach(id => {
      firebase.firestore().collection('complaint').doc(id).collection('message').onSnapshot(subSnapshot => {
        subSnapshot.docs.forEach(subDoc => {
          callback({
            id: id,
            message: {
              ...subDoc.data(),
              id: subDoc.id
            }
          })
        })
      })
    })
  }
  static close(id, value) {
    let status = 'pending'
    // if (value) status = 'closed'
    // else status = 'opened'
    value ? status = 'closed' : status = 'opened'
    firebase.firestore().collection('complaint').doc(id).update({
        isClosed: value,
        status: status,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
  }
  static async doReply(data) {
    firebase.firestore().collection('complaint').doc(data.id).update({
      status: 'opened',
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
    const response = await graphqlClient.mutate({
      mutation: gql `
          mutation complaintCreate(
              $data: ComplaintInput!
          ) {
              complaintCreate(data: $data)
          }
        `,

      variables: {
        data,
      },
    });
    return response.data.complaintCreate;
  }
  static async doSendNotification(data) {
    console.log(data);
    const response = await graphqlClientNotification.mutate({
      mutation: gql `
            mutation notificationCreate(
                $data: notificationInput!
            ) {
              notificationCreate(data: $data)
            }
          `,

      variables: {
        data,
      },
    });
    return response.data.notificationCreate;
  }
}