import { ApplicationService } from '@/modules/seller/application-service';
import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
    rejectLoading: false,
    contactLoading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
    rejectLoading: (state) => !!state.rejectLoading,
    contactLoading: (state) => !!state.contactLoading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },

    REJECTED_ALL_STARTED(state) {
      state.rejectLoading = true;
    },

    REJECTED_ALL_SUCCESS(state) {
      state.rejectLoading = false;
    },

    REJECTED_ALL_ERROR(state) {
      state.rejectLoading = false;
    },

    REJECTED_STARTED(state) {
      state.rejectLoading = true;
    },

    REJECTED_SUCCESS(state) {
      state.rejectLoading = false;
    },

    REJECTED_ERROR(state) {
      state.rejectLoading = false;
    },

    CONTACT_ALL_STARTED(state) {
      state.contactLoading = true;
    },

    CONTACT_ALL_SUCCESS(state) {
      state.contactLoading = false;
    },

    CONTACT_ALL_ERROR(state) {
      state.contactLoading = false;
    },
  },

  actions: {
    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED');

        await ApplicationService.destroyAll([id]);

        commit('DESTROY_SUCCESS');

        Message.success(i18n('entities.application.destroy.success'));

        // routerAsync().push('/application');

        dispatch(
          `${'seller/application'}/doFetch`,
          rootGetters[`${'seller/application'}/filter`],
          {
            root: true,
          },
        );
        // dispatch(
        //   `${'application/list'}/doFetch`,
        //   rootGetters[`${'application/list'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll(
      { commit, dispatch, rootGetters },
      ids,
    ) {
      try {
        commit('DESTROY_ALL_STARTED');

        await ApplicationService.destroyAll(ids);

        commit('DESTROY_ALL_SUCCESS');

        // dispatch(`${'application/list'}/doUnselectAll`, null, {
        //   root: true,
        // });

        dispatch(`${'seller/application'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(
          i18n('entities.application.destroyAll.success'),
        );

        // routerAsync().push('/application');

        dispatch(
          `${'seller/application'}/doFetch`,
          rootGetters[`${'seller/application'}/filter`],
          {
            root: true,
          },
        );
        // dispatch(
        //   `${'application/list'}/doFetch`,
        //   rootGetters[`${'application/list'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },

    async doRejectAll({ commit, dispatch }, ids) {
      try {
        commit('REJECTED_ALL_STARTED');

        await ApplicationService.rejectAll(ids);

        commit('REJECTED_ALL_SUCCESS');

        // dispatch(`${'application/list'}/doUnselectAll`, null, {
        //   root: true,
        // });

        dispatch(`${'seller/application'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(
          i18n('entities.application.rejectedAll.success'),
        );

        // routerAsync().push('/application');

        // dispatch(
        //   `${'seller/application'}/doFetch`,
        //   rootGetters[`${'seller/application'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
        // dispatch(
        //   `${'application/list'}/doFetch`,
        //   rootGetters[`${'application/list'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('REJECTED_ALL_ERROR');
      }
    },

    async doContactAll({ commit, dispatch }, ids) {
      try {
        commit('CONTACT_ALL_STARTED');

        await ApplicationService.contactAll(ids);

        commit('CONTACT_ALL_SUCCESS');

        // dispatch(`${'application/list'}/doUnselectAll`, null, {
        //   root: true,
        // });

        dispatch(`${'seller/application'}/doUnselectAll`, null, {
          root: true,
        });
        dispatch(`${'seller/application'}/doFetch`, null, {
          root: true,
        });

        Message.success(
          i18n('entities.application.update.success'),
        );

        // routerAsync().push('/application');

        // dispatch(
        //   `${'seller/application'}/doFetch`,
        //   rootGetters[`${'seller/application'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
        // dispatch(
        //   `${'application/list'}/doFetch`,
        //   rootGetters[`${'application/list'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('CONTACT_ALL_ERROR');
      }
    },
  },
};
