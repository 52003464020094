import importerStore from '@/shared/importer/importer-store';
import { BranchService } from '@/modules/branch/branch-service';
import branchImporterFields from '@/modules/branch/branch-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  BranchService.import,
  branchImporterFields,
  i18n('entities.seller.importer.fileName'),
  i18n('entities.seller.importer.hint'),
);
