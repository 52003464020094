import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ImporterListPage = () => 
  import('@/modules/importer/components/importer-list-page.vue')  
const ImporterFormPage = () => 
  import('@/modules/importer/components/importer-form-page.vue')  

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'importer',
        path: '/importer',
        component: ImporterListPage,
        meta: {
          auth: true,
          permission: Permissions.values.importerRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.importation') 
          next();
        },
      },
      {
        name: 'importerNew',
        path: '/importer/new',
        component: ImporterFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.importerCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.importation') +
            ' | ' +
            i18n('entities.importer.new.title') 
          next();
        },
      },
    ],
  },
];



