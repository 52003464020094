import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const FactoryListPage = () => 
  import('@/modules/factory/components/factory-list-page.vue')  
const FactoryFormPage = () => 
  import('@/modules/factory/components/factory-form-page.vue')  
const FactoryScanPage = () => 
  import('@/modules/factory/components/factory-exporter-scan-page.vue')  
  

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'factory',
        path: '/factory',
        component: FactoryListPage,
        meta: {
          auth: true,
          permission: Permissions.values.importerRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.factory') 
          next();
        },
      },
      {
        name: 'factoryNew',
        path: '/factory/new',
        component: FactoryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.importerCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.factory') +
            ' | ' +
            i18n('entities.factory.new.title') 
          next();
        },
      },

      {
        name: 'factoryScan',
        path: '/factory/scan',
        component: FactoryScanPage,
        meta: {
          auth: true,
          permission: Permissions.values.importerCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.factory') +
            ' | ' +
            i18n('entities.factory.new.title') 
          next();
        },
      },
    ],
  },
];



