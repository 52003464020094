import { AccessoryModel } from '@/modules/accessory/accessory-model';

const { fields } = AccessoryModel;

export default [
  fields.name,
  fields.description,
  fields.unitPrice,
  fields.photos,
];
