import accessoryListStore from '@/modules/accessory/accessory-list-store';
import accessoryViewStore from '@/modules/accessory/accessory-view-store';
import accessoryImporterStore from '@/modules/accessory/accessory-importer-store';
import accessoryFormStore from '@/modules/accessory/accessory-form-store';
import accessoryDestroyStore from '@/modules/accessory/accessory-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: accessoryDestroyStore,
    form: accessoryFormStore,
    list: accessoryListStore,
    view: accessoryViewStore,
    importer: accessoryImporterStore,
  },
};
