import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const AccessoryListPage = () =>
  import('@/modules/accessory/components/accessory-list-page.vue');
const AccessoryFormPage = () =>
  import('@/modules/accessory/components/accessory-form-page.vue');

const AccessoryExportPage = () => 
  import('@/modules/accessory/components/accessory-export-page.vue')  
// const AccessoryViewPage = () =>
//   import('@/modules/accessory/components/accessory-view-page.vue');
// const AccessoryImporterPage = () =>
//   import('@/modules/accessory/components/accessory-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'accessory',
        path: '/accessory',
        component: AccessoryListPage,
        meta: {
          auth: true,
          permission: Permissions.values.accessoryView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.accessories') 
          next();
        },
      },
      {
        name: 'accessoryNew',
        path: '/accessory/new',
        component: AccessoryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.accessoryCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.accessories') 
          next();
        },
      },
      {
        name: 'accessoryEdit',
        path: '/accessory/:id/edit',
        component: AccessoryFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.accessoryEdit,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.accessories') 
          next();
        },
      },
      {
        name: 'accessoryExport',
        path: '/accessory/:id/export',
        component: AccessoryExportPage,
        meta: {
          auth: true,
          // permission: Permissions.values.accessoryEdit,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.accessories') 
          next();
        },
      },
      // {
      //   name: 'accessoryImporter',
      //   path: '/accessory/import',
      //   component: AccessoryImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.accessoryImport,
      //   },
      // },
      // {
      //   name: 'accessoryView',
      //   path: '/accessory/:id',
      //   component: AccessoryViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.accessoryView,
      //   },
      //   props: true,
      // },
    ],
  },
];
