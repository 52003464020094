//
//
//
//
//
//
//
//

export default {
  name: 'app-list-item-image',

  props: ['value'],

  computed: {
    isBlank() {
      return !this.value || !this.value.length;
    },
  },
};
