import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase/app';
import 'firebase/firestore'
// import { storeAsync } from '@/app-module';

export class CountryService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation COUNTRY_CREATE($data: CountryInput!) {
          countryCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.countryCreate;
  }

  static async update(id, data) {
    // await firebase.firestore().collection('country').doc(id).update({
    //   name: data.name
    // })
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation COUNTRY_UPDATE($id: String!, $data: CountryInput!) {
          countryUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.countryUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation COUNTRY_DESTROY($ids: [String!]!) {
          countryDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.countryDestroy;
  }

  static async find(id) {
    if (!id) {
      return null
    }
    
    return FirebaseRepository.mapDocument(
      await firebase.firestore().collection('country').doc(id).get()
    )
  }

  static async list(filter, orderBy, limit, offset) {
    console.log(filter, orderBy, limit, offset);
    // let response = []
    // await firebase.firestore().collection('country').onSnapshot({ includeMetadataChanges: true }, async () => {
    // const countries = await firebase.firestore().collection('country').get({ source: 'cache' });
    // firebase.firestore().collection('country').onSnapshot(async () => {
    // 
    // });
    let query = firebase.firestore().collection('country').orderBy('createdAt', 'desc')
    // if (orderBy) {
    //   query = query.orderBy()
    // }
    const countries = FirebaseRepository.mapCollection(
      await query.get()
    )
    return {
      rows: countries,
      count: countries.length,
    }
  }

  

  static async listCitiesAndRegions() {
    let response = []
    // await firebase.firestore().collection('country').onSnapshot({ includeMetadataChanges: true }, async () => {
    // const countries = await firebase.firestore().collection('country').get({ source: 'cache' });
    // await firebase.firestore().collection('country').onSnapshot(async () => {
    const countries = await firebase.firestore().collection('country').get();
    countries.forEach(async (doc) => {
      let country = doc.data();
      country['id'] = doc.id;
      country['regions'] = []
      let regions = await firebase.firestore().collection("country").doc(doc.id).collection("regions").get()
      regions.forEach(async (region) => {
        let regionInCountry = region.data()
        regionInCountry['id'] = region.id;

        country['regions'].push(regionInCountry)
        // regionInCountry['country'] = country.name

        // this.regions.push(regionInCountry)
      })

      response.push(country);
    });
    // });

    return response
  }
}