import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ProductListPage = () =>
  import('@/modules/product/components/product-list-page.vue');
const ProductFormPage = () =>
  import('@/modules/product/components/product-form-page.vue');

const ProductExportPage = () => 
  import('@/modules/product/components/product-export-page.vue')  
// const ProductViewPage = () =>
//   import('@/modules/product/components/product-view-page.vue');
// const ProductImporterPage = () =>
//   import('@/modules/product/components/product-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'product',
        path: '/product',
        component: ProductListPage,
        meta: {
          auth: true,
          permission: Permissions.values.productView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.products') 
          next();
        },
      },
      {
        name: 'productNew',
        path: '/product/new',
        component: ProductFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.productCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.products') 
          next();
        },
      },
      {
        name: 'productEdit',
        path: '/product/:id/edit',
        component: ProductFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.productEdit,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.products') 
          next();
        },
      },
      {
        name: 'productExport',
        path: '/product/:id/export',
        component: ProductExportPage,
        meta: {
          auth: true,
          // permission: Permissions.values.productEdit,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.products') 
          next();
        },
      },
      // {
      //   name: 'productImporter',
      //   path: '/product/import',
      //   component: ProductImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.productImport,
      //   },
      // },
      // {
      //   name: 'productView',
      //   path: '/product/:id',
      //   component: ProductViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.productView,
      //   },
      //   props: true,
      // },
    ],
  },
];
