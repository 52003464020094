//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';
export default {
  name: 'app-pincode-item-page',
  props: ['id', 'loading', 'storePath','isItem'],

  data() {
    return {
      pinCode:'',
    };
  },
  computed: {
    ...mapGetters({
      settings:'settings/settings',
    }),
  },
  async created(){
    // await this.doFindSettings()
  },
  methods: {
    ...mapActions({
      doFindSettings:'settings/doFind',
      isItemExist:'product/list/isItemExist'
    }),
    i18n(text) {
      return i18n(text);
    },
    async doSubmit() {
      if(this.settings && this.settings.pinCode === this.pinCode){
        await this.$emit('submit', this.id)
      }
      else{
        Message.error(i18n('errors.incorrectPinCode'))
        this.$emit('close')
      }
    },
  },
};
