//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuItem from '@/modules/layout/components/menu-item.vue';

import { mapGetters, mapActions } from 'vuex';
import { SettingsPermissions } from '@/modules/settings/settings-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import { ProductPermissions } from '@/modules/product/product-permissions';
import { AccessoryPermissions } from '@/modules/accessory/accessory-permissions';
import { SparePartPermissions } from '@/modules/sparePart/sparePart-permissions';
import firebase from 'firebase/app';
import 'firebase/firestore';

// import {i18n} from '@/i18n'
// import Message from '@/shared/message/message';

export default {
  name: 'app-menu',

  components: {
    [MenuItem.name]: MenuItem,
  },

  created() {
    const routePath = this.$route.path;
    const firstPart = routePath.split('/')[1]
    if (firstPart == '') {
      this.active = 'home'
    } else {
      this.active = firstPart
    }
    // console.log('Active Path = ', this.active)
    
    firebase.firestore().collection('complaint').where('status', '==', 'pending').onSnapshot(snap => {
      this.complaintsCounter = snap.docs.length
    })

    if (this.isOwner) {
      firebase.firestore().collection('activation').where('limitExceeded', '==', true).where('unread', '==', true).onSnapshot(snap => {
        // // this.limitExceededCounter = snap.docs.length
        this.limitExceededCounter = snap.size
  
        snap.docChanges().forEach(change => {
          if (change.type === 'added' && change.doc.data().showAlert ) {
            console.log('Added Document: ', change.doc.data());
            this.activatorIds.push(change.doc.data().id)
            this.pushNotify();
          }
          // if (change.type === 'modified') {
          //   console.log('Modified Document: ', change.doc.data());
          //   this.pushNotify();
          // }
          // if (change.type === 'removed') {
          //   console.log('Removed Document: ', change.doc.data());
          // }
        });
      })
    }
  },

  data() {
    return {
      active: '',
      complaintsCounter: 0,
      limitExceededCounter: 0,
      activatorIds: [],
      // path: this.routePath(), 
    };
  },
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      roles: 'auth/roles',
      isOwner: 'auth/isOwner',
    }),

    hasPermissionToSettings() {
      return new SettingsPermissions(this.currentUser).edit;
    },
    hasPermissionToAuditLog() {
      return new AuditLogPermissions(this.currentUser).read;
    },
    hasPermissionToIam() {
      return new IamPermissions(this.currentUser).read;
    },
    hasPermissionToProduct() {
      return new ProductPermissions(this.currentUser).read;
    },
    hasPermissionToAccessory() {
      return new AccessoryPermissions(this.currentUser).read;
    },
    hasPermissionToSparePart() {
      return new SparePartPermissions(this.currentUser).read;
    },
    hasPermissionToDatabaseGroup() {
      return this.hasPermissionToProduct || 
             this.hasPermissionToProduct || 
             this.hasPermissionToProduct || 
             this.hasPermissionToIam
    },

    asideWidth() {
      if (!this.collapsed) {
        return '200px';
      }
      return '0px';
    },
    isActive() {
      const routePath = this.$route.path;
      const active = routePath === this.path || routePath.startsWith(this.path + '/');
      return active
    },
    routePath() {
      return this.$route.path;
      // return this.$router.currentRoute.fullPath;
    },
    openDatabaseGroup() {
      return this.active == 'product'     || 
             this.active == 'spare-part'  || 
             this.active == 'accessory'   || 
             this.active == 'iam'         ||
             this.active == 'cities'      ||
             this.active == 'regions'     ||
             this.active == 'branch'      ||
             this.active == 'distributor' ||
             this.active == 'barcode'     ||
             this.active == 'bills'       
    },
    openUserGroup() {
      return this.active == 'admin' || this.active == 'client'
    },
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    // routePath() {
    //   return this.$router.currentRoute.fullPath;
    //   // return window.location.pathname
    // },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + '/');

      return {
        active,
      };
    },
    routeTo(path) {
      console.log('Path To => ', path)
    },
    pushNotify() {
      // Message.error(i18n('entities.activation.notification.limitExceeded'));
      const dismiss = this.$q.notify({
        message: this.i18n('entities.activation.notification.limitExceeded'),
        timeout: 0,
        color: 'red',
        textColor: 'white',
        position: 'top',
        // icon: 'fas fa-times-circle',
        icon: 'fas fa-exclamation-triangle',
        actions:[
          { label: this.i18n('common.dismiss'), color: 'yellow', handler: () => { dismiss(); this.dismissShowAlert() } }
          // { icon: 'fas fa-times', color: 'AquaHaze', handler: () => { dismiss() } }
          // { label: 'Dismiss', 'no-caps': true, color: 'yellow', handler: () => { dismiss() } }
        ]
      })
    },
    async dismissShowAlert() {
      this.activatorIds.forEach(id => {
        firebase.firestore().collection('activation').doc(id).update({ showAlert: false })
      });
    },
  },
  watch: {
    routePath() {
      const routePath = this.$route.path;
      const firstPart = routePath.split('/')[1]
      //  console.log(firstPart)
      if (firstPart == '') {
        this.active = 'home'
      } else {
        this.active = firstPart
      }
      //  console.log('Active Path = ', this.active)
    }
  },
};
