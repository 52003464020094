import countryListStore from '@/modules/country/country-list-store';
import countryFormStore from '@/modules/country/country-form-store';
import countryDestroyStore from '@/modules/country/country-destroy-store';
// import countryViewStore from '@/modules/country/country-view-store';

export default {
  namespaced: true,

  modules: {
    form: countryFormStore,
    list: countryListStore,
    destroy: countryDestroyStore,
    // view: countryViewStore,
  },
};
