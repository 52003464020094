import firebase from 'firebase/app';
import 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    menuCollapsed: false,
    isMobile: false,
    is_screen_xs: false,
    is_screen_sm: false,
    is_screen_md: false,
    is_screen_lg: false,
    is_labtop: false,
  },

  getters: {
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    is_screen_xs: (state) => !!state.is_screen_xs,
    is_screen_sm: (state) => !!state.is_screen_sm,
    is_screen_md: (state) => !!state.is_screen_md,
    is_screen_lg: (state) => !!state.is_screen_lg,
    is_labtop: (state) => !!state.is_labtop,

    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
  },

  mutations: {
    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.isMobile = payload.width < 576;
      state.is_screen_xs = payload.width < 600;
      state.is_screen_sm = payload.width >= 600 && payload.width < 1024;
      state.is_screen_md = payload.width >= 1024 && payload.width < 1440;
      state.is_screen_lg = payload.width >= 1440 && payload.width < 1920;
      state.is_labtop = payload.width <= 1400 && payload.height <= 800;
    },
  },

  actions: {
    productListener({commit}){
      console.log(commit);
      firebase.firestore().collection('items').onSnapshot(snapshot => {
        let arr = snapshot.docChanges().map(I => I.doc.data())
        let allDocs = snapshot.docs.map(I => I.data())
        arr.forEach(async item => {
          try {
            const quantity = allDocs.filter(I => I.itemId === item.itemId && I.status === 'inStock').length
            await firebase.firestore().collection(item.itemType).doc(item.itemId).update({quantity:quantity})
          } catch (error) {
            console.log(error);
          }
        })
      })
    },

    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },
  },
};
