import { SellerModel } from '@/modules/seller/seller-model';

const { fields } = SellerModel;

export default [
  fields.name,
  fields.description,
  fields.unitPrice,
  fields.photos,
];
