import citiesRegionsListStore from '@/modules/cities/citiesRegions-list-store';
import citiesRegionsFormStore from '@/modules/cities/citiesRegions-form-store';
// import productViewStore from '@/modules/product/product-view-store';
// import productImporterStore from '@/modules/product/product-importer-store';
// import productDestroyStore from '@/modules/product/product-destroy-store';

export default {
  namespaced: true,

  modules: {
    form: citiesRegionsFormStore,
    list: citiesRegionsListStore,
    // destroy: productDestroyStore,
    // view: productViewStore,
    // importer: productImporterStore,
  },
};
