import importerStore from '@/shared/importer/importer-store';
import { SellerService } from '@/modules/seller/seller-service';
import sellerImporterFields from '@/modules/seller/seller-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  SellerService.import,
  sellerImporterFields,
  i18n('entities.seller.importer.fileName'),
  i18n('entities.seller.importer.hint'),
);
