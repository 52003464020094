//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex';
import Message from '@/shared/message/message';
export default {
  name: 'app-delete-item-page',
  props: ['id', 'loading', 'storePath','isItem'],

  data() {
    return {
      pinCode:'',
      step:1
    };
  },
  computed: {
    ...mapGetters({
      settings:'settings/settings',
    }),
    // ...mapGetters({
    //   loading: `${this.storePath}/loading`,
    // })
  },
  async created(){
    // await this.doFindSettings()
  },
  methods: {
    ...mapActions({
      doFindSettings:'settings/doFind',
      isItemExist:'product/list/isItemExist'
    }),
    i18n(text) {
      return i18n(text);
    },
    async doSubmit() {
      if(this.settings.pinCode && this.settings.pinCode !== ''){
        if(this.step === 1){
          return this.step = 2
        }
        if(this.step === 2){
          if(this.settings && this.settings.pinCode === this.pinCode){
            // await this.$emit('submit', this.id)
            if(this.isItem && await this.isItemExist(this.id)){
              Message.error(i18n('errors.itemCannotBeDeleted'))
              this.$emit('close')
            }
            else{
              await this.$emit('submit', this.id)
              this.$emit('close')
            }
          }
          else{
            Message.error(i18n('errors.incorrectPinCode'))
            this.$emit('close')
          }
        }

      }
      else{
        if(this.isItem && await this.isItemExist(this.id)){
          Message.error(i18n('errors.itemCannotBeDeleted'))
          this.$emit('close')
        }
        else{
          await this.$emit('submit', this.id)
          this.$emit('close')
          
        }
        // await this.$emit('submit', this.id)
      }
    },
  },
};
