import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-sparePart';

export class SparePartService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SPARE_PART_UPDATE(
          $id: String!
          $data: SparePartInput!
        ) {
          sparePartUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.sparePartUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SPARE_PART_DESTROY($ids: [String!]!) {
          sparePartDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.sparePartDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SPARE_PART_CREATE($data: SparePartInput!) {
          sparePartCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.sparePartCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation SPARE_PART_IMPORT(
          $data: SparePartInput!
          $importHash: String!
        ) {
          sparePartImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.sparePartImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query SPARE_PART_FIND($id: String!) {
          sparePartFind(id: $id) {
            id
            name
            brand
            features
            barcode
            qrCode
            quantity
            warrantyPeriod
            photo {
              id
              name
              sizeInBytes
              publicUrl
              privateUrl
            }
            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.sparePartFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query SPARE_PART_LIST(
          $filter: SparePartFilterInput
          $orderBy: SparePartOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          sparePartList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              name
              brand
              features
              barcode
              qrCode
              quantity
              warrantyPeriod
              photo {
                id
                name
                sizeInBytes
                publicUrl
                privateUrl
              }
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.sparePartList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query SPARE_PART_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          sparePartAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.sparePartAutocomplete;
  }
}