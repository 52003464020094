import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const CountryListPage = () =>
  import('@/modules/country/components/country-list-page.vue');

export default [
    {
      name: '',
      path: '',
      exact: true,
      component: Layout,
      meta: { auth: true },
      children: [
        {
          name: 'country',
          path: '/country',
          component: CountryListPage,
          meta: {
            auth: true,
            permission: Permissions.values.countryRead,
          },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.country') 
            next();
          },
        },
      ]
    }
]