//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n';

export default {
  name: 'app-menu-item',
  props: ['path', 'label', 'icon', 'activeIcon'],

  // data() {
  //   return {
  //     activeItem: this.isActive,
  //   }
  // },
  computed: {
    anchor() {
      if (i18n.locale == 'ar') {
        return "center left"
      }
      return "center right"
    },
    isActive() {
      const routePath = this.$route.path;
      const active = routePath === this.path || routePath.startsWith(this.path + '/');
      return active
    },
  },

  methods: {
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + '/');

      return {
        active
      };
    },
  },
};
