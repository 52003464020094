import { ExporterService } from '@/modules/exporter/exporter-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    bills:[],
    inStock: false
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    bills: (state) => state.bills,
    inStock: (state) => state.inStock
  },

  mutations: {
    SET_BILLS(state,payload){
      state.bills = payload
    },
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    IN_STOCK(state,payload){
      state.inStock = payload
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await ExporterService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/exporter');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await ExporterService.create(values);
        // await ExporterService.incrementQuantityByFieldValue(values.itemType, values.itemId, 1)
        commit('CREATE_SUCCESS');
        // Message.success(i18n('entities.exporter.create.success'));
        // routerAsync().push('/exporter');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await ExporterService.update(id, values);
        // await ExporterService.decrementQuantityByFieldValue(values.itemType, values.itemId, 1)
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.exporter.update.success'));
        // routerAsync().push('/exporter');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async getBills({commit}){
      const data = await ExporterService.getBills()
      commit('SET_BILLS',data)
    },

    async isProductInStock({commit},payload) {

      const inStock = await ExporterService.productInStock(payload.item,payload.count)
      commit("IN_STOCK",inStock)
      if (!inStock) {
        Message.error(i18n('entities.exporter.update.fail'));
      }


    },
    async doExport({ commit }, { sellerId, sellerType, values }) {
      try {
        commit('UPDATE_STARTED');
        await ExporterService.exportItemsByTransaction(sellerId, sellerType, values);
        // await ExporterService.decrementQuantityByFieldValue(values.itemType, values.itemId, 1)
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.exporter.update.success'));
        // routerAsync().push('/exporter');
        return true
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
        return false
      }
    },

    
  },
};
