import { setLanguageCode } from '@/i18n';
// import service from '@/modules/auth/auth-service';
// await service.updateSystemLanguageForCurrentUser(language)
import { storeAsync } from '@/app-module';


export class I18nUtil {
  static async doChangeLanguage(language, loading) {
    await storeAsync().dispatch('auth/doUpdateSystemLanguage', language)
    setLanguageCode(language);
    if (loading || !loading) {
      document.location.reload();
    }
  }
}
