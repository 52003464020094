import { SparePartModel } from '@/modules/sparePart/sparePart-model';

const { fields } = SparePartModel;

export default [
  fields.id,
  fields.name,
  fields.description,
  fields.unitPrice,
  fields.photos,
  fields.createdAt
];
