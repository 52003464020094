import firebase from 'firebase/app';
import 'firebase/auth';
import config from '@/config';
import 'firebase/app-check';

export default function firebaseInit() {
  const app = firebase.initializeApp(config.firebaseConfig);
  // Cashe 
  // firebase.firestore().settings({
  //   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  // });
  // firebase.firestore().enablePersistence().catch(err => console.log(err))

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  // const appCheck = firebase.appCheck();
  // appCheck.activate('6LfxEfUaAAAAAMBWpJd4vd_x6eFl3yKYJ9NSz4Be');
  // console.log('appCheck = ', appCheck);
  
  app.auth();
}
