import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ExporterListPage = () => 
  import('@/modules/exporter/components/exporter-list-page.vue')  
  const BillsListPage = () => 
  import('@/modules/exporter/components/bills/bills-list-page.vue')  
const ExporterFormPage = () => 
  import('@/modules/exporter/components/1-exporter-form-page.vue')  
const ExporterScanPage = () => 
  import('@/modules/exporter/components/3-exporter-scan-page.vue') 

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'export',
        path: '/export',
        component: ExporterListPage,
        meta: {
          auth: true,
          permission: Permissions.values.exporterRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.exporter.label') 
          next();
        },
      },
      {
        name: 'bills',
        path: '/bills',
        component: BillsListPage,
        meta: {
          auth: true,
          permission: Permissions.values.exporterRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.exporter.label') 
          next();
        },
      },
      {
        name: 'exportNew',
        path: '/export/new',
        component: ExporterFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.exporterCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.exporter.label')  +
            ' | ' +
            i18n('entities.exporter.new.title') 
          next();
        },
      },
      {
        name: 'exportScan',
        path: '/export/:sellerId/scan',
        props: true,
        component: ExporterScanPage,
        meta: {
          auth: true,
          permission: Permissions.values.exporterCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.exporter.label')  +
            ' | ' +
            i18n('entities.exporter.pleaseScanItems') 
          next();
        },
      },
    ],
  },
];



