import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const BranchListPage = () =>
  import('@/modules/branch/components/branch-list-page.vue');
// const DistributorListPage = () =>
//   import('@/modules/branch/components/branch-distributor-page.vue');

// const BranchListPage = () =>
//   import('@/modules/branch/components/branch-list-page.vue');
// const BranchFormPage = () =>
//   import('@/modules/branch/components/branch-form-page.vue');

// const BranchExportPage = () => 
//   import('@/modules/branch/components/branch-export-page.vue')  
// const BranchViewPage = () =>
//   import('@/modules/branch/components/branch-view-page.vue');
// const BranchImporterPage = () =>
//   import('@/modules/branch/components/branch-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'branch',
        path: '/branch',
        component: BranchListPage,
        meta: {
          auth: true,
          permission: Permissions.values.branchView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.branch') 
          next();
        },
      },
      // {
      //   name: 'distributor',
      //   path: '/distributor',
      //   component: DistributorListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.distributorView,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title =
      //       i18n('app.title') +
      //       ' | ' +
      //       i18n('menu.distributor') 
      //     next();
      //   },
      // },
      // {
      //   name: 'branch',
      //   path: '/branch',
      //   component: BranchListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchView,
      //   },
      // },
      // {
      //   name: 'branchNew',
      //   path: '/branch/new',
      //   component: BranchFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchCreate,
      //   },
      // },
      // {
      //   name: 'branchEdit',
      //   path: '/branch/:id/edit',
      //   component: BranchFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'branchExport',
      //   path: '/branch/:id/export',
      //   component: BranchExportPage,
      //   meta: {
      //     auth: true,
      //     // permission: Permissions.values.branchEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'branchImporter',
      //   path: '/branch/import',
      //   component: BranchImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchImport,
      //   },
      // },
      // {
      //   name: 'branchView',
      //   path: '/branch/:id',
      //   component: BranchViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchView,
      //   },
      //   props: true,
      // },
    ],
  },
];
