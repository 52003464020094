import exporterListStore from '@/modules/exporter/exporter-list-store';
import exporterFormStore from '@/modules/exporter/exporter-form-store';
// import exporterViewStore from '@/modules/exporter/exporter-view-store';
// import exporterExporterStore from '@/modules/exporter/exporter-exporter-store';
import exporterDestroyStore from '@/modules/exporter/exporter-destroy-store';

export default {
  namespaced: true,

  modules: {
    form: exporterFormStore,
    list: exporterListStore,
    destroy: exporterDestroyStore,
    // view: exporterViewStore,
    // exporter: exporterExporterStore,
  },
};
