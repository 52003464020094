import { ImporterService } from '@/modules/importer/importer-service';
import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async updateQuantityByTransaction({commit}, {collectionName, docId, quantity}){
      console.log(commit);
      ImporterService.updateQuantityByTransaction(collectionName, docId, quantity)
    },
    async doDestroy({ commit }, id) {
      try {
        commit('DESTROY_STARTED');

        await ImporterService.destroyAll([id]);

        commit('DESTROY_SUCCESS');

        Message.success(i18n('entities.importer.destroy.success'));

        // routerAsync().push('/importer');
        // console.log(rootGetters[`${'importer/list'}/filter`]);
        // dispatch(
        //   `${'importer/list'}/doFetch`,
        //   rootGetters[`${'importer/list'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll(
      { commit, dispatch, rootGetters },
      ids,
    ) {
      try {
        commit('DESTROY_ALL_STARTED');

        await ImporterService.destroyAll(ids);

        commit('DESTROY_ALL_SUCCESS');
        console.log(rootGetters[`${'importer/list'}/filter`]);
        dispatch(`${'importer/list'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(
          i18n('entities.importer.destroyAll.success'),
        );

        // routerAsync().push('/importer');

        // dispatch(
        //   `${'importer/list'}/doFetch`,
        //   rootGetters[`${'importer/list'}/filter`],
        //   {
        //     root: true,
        //   },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },
  },
};
