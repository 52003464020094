import importerStore from '@/shared/importer/importer-store';
import { AccessoryService } from '@/modules/accessory/accessory-service';
import accessoryImporterFields from '@/modules/accessory/accessory-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  AccessoryService.import,
  accessoryImporterFields,
  i18n('entities.accessory.importer.fileName'),
  i18n('entities.accessory.importer.hint'),
);
