import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-importer';
import { storeAsync } from '@/app-module';
import firebase from 'firebase/app'
import 'firebase/firestore'
import FirebaseQuery from '@/shared/firebase/firebaseQuery'
// import { ProductService } from '@/modules/product/product-service';

export class ImporterService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_UPDATE(
          $id: String!
          $data: ImporterInput!
        ) {
          importerUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.importerUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_DESTROY($ids: [String!]!) {
          importerDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.importerDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_CREATE($data: ImporterInput!) {
          importerCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });
    return response.data.importerCreate;
  }

  static async importer(values, importerHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_IMPORT(
          $data: ImporterInput!
          $importerHash: String!
        ) {
          importerImporter(data: $data, importerHash: $importerHash)
        }
      `,

      variables: {
        data: values,
        importerHash,
      },
    });

    return response.data.importerImporter;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query IMPORTER_FIND($id: String!) {
          importerFind(id: $id) {
            id
            serialNumber
            qrCode
            itemId              
            itemType
            status
            warrantyStatus
            activationDate
            expirationDate
            itemDetails {
              name
              brand
              features
              barcode
              qrCode
              quantity
              warrantyPeriod
              photo {
                id
                name
                publicUrl
              }
            }
            seller {
              name
              address
              telephone
              manager {
                name
                phoneNumber
              }
              isOnlineStore
              websiteUrl
              taxFacilityNumber
              countryId
              cityId
              regionId
              sellerType
            }
            exportedBy
            exportedAt
            activatedBy {
              fullName
              phoneNumber
            }

            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });
    return response.data.importerFind;

    // let cacheDocs =  (await firebase.firestore().collection('items').where('id','==',id).get({source: "cache"})).docs
    // let data;
    // if(cacheDocs.length){
    //   data = cacheDocs[0].data()
    //   console.log('Cached Item');
    // }
    // else {
    // cacheDocs =  (await firebase.firestore().collection('items').where('id','==',id).get()).docs
    //   if(cacheDocs.length){
    //     data = cacheDocs[0].data()
    //     console.log('Server Item');
    //   }
    // }
    // if(data){
    //   let cacheProduct =  (await firebase.firestore().collection('product').where('id','==',data.itemId).get({source: "cache"})).docs
    //   let product;
    //   if(cacheProduct.length){
    //     product = cacheProduct[0].data()
    //     console.log('Cached Product');
    //   }
    //   else {
    //     cacheProduct =  (await firebase.firestore().collection('product').where('id','==',data.itemId).get()).docs
    //     if(cacheProduct.length){
    //       product = cacheProduct[0].data()
    //       console.log('Cached Product');
    //     }
    //   }
    //   data.itemDetails = product
    // }
    // return data
  }

  static filter(rows,filter, orderBy, limit, offset){
    console.log(filter, orderBy, limit,offset);
    const query = FirebaseQuery.forList({
      orderBy: orderBy || 'createdAt_DESC',
    });  
    if (filter) {
      if (filter.id) {
        query.appendId('id', filter.id);
      }
      if (filter.serialNumber) {
        query.appendEqual('serialNumber', filter.serialNumber);
      }
      if (filter.qrCode) {
        query.appendEqual('qrCode', filter.qrCode);
      }
      if (filter.itemId) {
        query.appendEqual('itemId', filter.itemId);
      }
      if (filter.itemType) {
        query.appendEqual('itemType', filter.itemType);
      }
      if (filter.status) {
        query.appendEqual('status', filter.status);
      }
      if (filter.warrantyStatus) {
        query.appendEqual('warrantyStatus', filter.warrantyStatus);
      }

      if (filter.activationDate) {
        query.appendEqual('activationDate', filter.activationDate);
      }
      if (filter.expirationDate) {
        query.appendEqual('expirationDate', filter.expirationDate);
      }
      if (filter.createdAt) {
        query.appendEqual('createdAt', filter.createdAt);
      }

      if (filter.exportedBy) {
        query.appendEqual('exportedBy', filter.exportedBy);
      }
      if (filter.exportedAt) {
        query.appendEqual('exportedAt', filter.exportedAt);
      }
      if (filter.exportedAtRange) {
        query.appendRange(
          'exportedAt',
          filter.exportedAtRange,
        );
      }

      if (filter.activationDateRange) {
        query.appendRange(
          'ctivationDate',
          filter.activationDateRange,
        );
      }
      if (filter.expirationDateRange) {
        query.appendRange(
          'expirationDate',
          filter.expirationDateRange,
        );
      }
      if (filter.createdAtRange) {
        query.appendRange(
          'createdAt',
          filter.createdAtRange,
        );
      }
    }
    rows = query.rows(rows)
    let count = query.count(rows)
    return {rows, count}
  }
  
  static async getFromCache(collectionName){
    let res;
    try {
      res = await firebase.firestore().collection(collectionName).get({source : "cache"})
      if(!res.docs.length){
        res = await firebase.firestore().collection(collectionName).get()
      }
    } catch (error) {
      res = await firebase.firestore().collection(collectionName).get()
    }
    if(res){
      console.log(`${collectionName} response came from ${res.metadata.fromCache ? 'CACHE': 'SERVER'} with size ${res.size}`);
      return res
    }
    else return null
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query IMPORTER_LIST(
          $filter: ImporterFilterInput
          $orderBy: ImporterOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          importerList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              serialNumber
              qrCode
              itemId              
              itemType
              status
              warrantyStatus
              activationDate
              expirationDate
              itemDetails {
                name
                brand
                features
                barcode
                qrCode
                quantity
                warrantyPeriod
                photo {
                  id
                  name
                  publicUrl
                }
              }
              seller {
                name
                address
                telephone
                manager {
                  name
                  phoneNumber
                }
                isOnlineStore
                websiteUrl
                taxFacilityNumber
                countryId
                cityId
                regionId
                sellerType
              }
              exportedBy
              exportedAt
              activatedBy {
                fullName
                phoneNumber
              }
              
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });
    return response.data.importerList;
      
    // console.log(callback);
    // let res = await this.getFromCache('items')
    // const productRes = await ProductService.list();
    // let products = productRes.rows
    // if(!listIsCalled){
    //   firebase.firestore().collection('items').where('status','==','inStock').onSnapshot(async snap => {
    //     console.log('exporter Snapshot meta from', snap.metadata.fromCache?'cache':'server','with size',snap.size);
    //     let {rows,count} = this.filter(snap.docs.map(I => I.data()),filter, orderBy, limit, offset)
    //     rows = rows.map(I => {
    //       return {
    //         ...I,
    //         createdAt: I.createdAt ?  new Date(I.createdAt.seconds*1000): I.createdAt,
    //         exportedAt: I.exportedAt ? new Date(I.createdAt.seconds*1000): I.exportedAt,
    //         expirationDate: I.expirationDate ? new Date(I.createdAt.seconds*1000): I.expirationDate,
    //         updatedAt: I.updatedAt ? new Date(I.createdAt.seconds*1000): I.updatedAt,
    //         itemDetails: products.find(U => U.id === I.itemId)
    //       }
    //     })
    //     callback(rows,count)
    //   })
    // }
    // else {
    //   const docs = (await firebase.firestore().collection('items').where('status','==','inStock').get()).docs
    //   let {rows,count} = this.filter(docs,filter, orderBy, limit, offset)
    //     rows = rows.map(I => {
    //       return {
    //         ...I,
    //         createdAt: I.createdAt ?  new Date(I.createdAt.seconds*1000): I.createdAt,
    //         exportedAt: I.exportedAt ? new Date(I.createdAt.seconds*1000): I.exportedAt,
    //         expirationDate: I.expirationDate ? new Date(I.createdAt.seconds*1000): I.expirationDate,
    //         updatedAt: I.updatedAt ? new Date(I.createdAt.seconds*1000): I.updatedAt,
    //         itemDetails: products.find(U => U.id === I.itemId)
    //       }
    //     })
    //     callback(rows,count)
    // }
    // callback({rows:response.data.importerList.rows,count:response.data.importerList.count})
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query IMPORTER_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          importerAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.importerAutocomplete;
  }

  static async updateQuantityByTransaction(collectionName, docId, quantity) {
    try {
      let docRef = firebase.firestore().collection(collectionName).doc(docId) 
      await firebase.firestore().runTransaction(async (t) => {
        const doc = await t.get(docRef);
        const newQuantity = doc.data().quantity + parseInt(quantity);
        t.update(docRef, { quantity: newQuantity});
      });
    
       console.log('Transaction success!');
    } catch (e) {
       console.log('Transaction failure:', e);
    }
  }

  static async incrementQuantityByFieldValue(collectionName, docId, quantity) {
    try {
      const count = parseInt(quantity)
      const increment = firebase.firestore.FieldValue.increment(count);

      // Document reference
      const docRef = firebase.firestore().collection(collectionName).doc(docId)

      // Update quantity 
      docRef.update({ quantity: increment });
    
       console.log('Increment quantity success!');
    } catch (e) {
       console.log('Increment quantity failure:', e);
       throw `Increment quantity failure:, ${e}`
    }
  }

  static async decrementQuantityByFieldValue(collectionName, docId, quantity) {
    try {
      const count = parseInt(quantity)
      const decrement = firebase.firestore.FieldValue.increment(-count);

      // Document reference
      const docRef = firebase.firestore().collection(collectionName).doc(docId)

      // Update quantity 
      docRef.update({ quantity: decrement });
    
       console.log('Decrement quantity success!');
    } catch (e) {
       console.log('Decrement quantity failure:', e);
    }
  }

//#region [ Serial Number Functions ]
  static async findUnusedSerialNumbers(limit) {
    let currentUser = storeAsync().getters['auth/currentUser']
    let collection 
    if (limit > 0) {
      collection = await firebase
        .firestore()
        .collection(`serialNumbersBank`)
        .where('used', '==', false).where('inProcess', '==', false).where('usedBy', '==', currentUser.id)
        .orderBy('serialNumber')
        .limit(limit)
        .get();

      if (collection.empty) {
        collection = await firebase
          .firestore()
          .collection(`serialNumbersBank`)
          .where('used', '==', false).where('inProcess', '==', false)
          .orderBy('serialNumber')
          .limit(limit)
          .get();
      }  
    } else {
      collection = await firebase
        .firestore()
        .collection(`serialNumbersBank`)
        .where('used', '==', false).where('inProcess', '==', false).where('usedBy', '==', currentUser.id)
        .orderBy('serialNumber')
        .get();

      if (collection.empty) {
        collection = await firebase
          .firestore()
          .collection(`serialNumbersBank`)
          .where('used', '==', false).where('inProcess', '==', false)
          .orderBy('serialNumber')
          .get();
      } 
    }

    //  console.log('collection = ', collection);
    if (collection.empty) {
      return [];
    }

    let list = [];

    collection.forEach((document) => {
      const item = Object.assign({}, document.data(), {
        id: document.id,
      });

      // this.replaceAllTimestampToDate(item);
      list.push(item);
    });

    await this.updateSerialNumberStatusAsInProcess(list.map(el => el.id), currentUser)

    return list;
  }
  
  static async updateSerialNumberStatusAsInProcess(ids, currentUser) {
    let batch = firebase.firestore().batch();
    
    ids.forEach(id => {
      let docRef = firebase.firestore().doc(`serialNumbersBank/${id}`) 
      batch.update(docRef, { inProcess: true, usedBy: currentUser.id });
    });
    
    batch.commit()
  }

  static async updateSerialNumberAsUsed(id) {
    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`serialNumbersBank/${id}`) 
    batch.update(docRef, { used: true, inProcess: false });
    
    batch.commit()
  }

  static async countUnusedSerialNumbers() {
    let chain = firebase.firestore().collection('serialNumber').where('used', '==', false);

    // if (filter) {
    //   Object.keys(filter).forEach((key) => {
    //     chain = chain.where(key, '==', filter[key]);
    //   });
    // }

    return (await chain.get()).size;
  }
//#endregion

//#region [ Zebra Printer ]

//#endregion
}