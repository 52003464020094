import importerListStore from '@/modules/importer/importer-list-store';
import importerFormStore from '@/modules/importer/importer-form-store';
import serialNumberStore from '@/modules/importer/serial-number-store';
// import importerViewStore from '@/modules/importer/importer-view-store';
// import importerImporterStore from '@/modules/importer/importer-importer-store';
import importerDestroyStore from '@/modules/importer/importer-destroy-store';

export default {
  namespaced: true,

  modules: {
    form: importerFormStore,
    list: importerListStore,
    serialNumber: serialNumberStore,
    destroy: importerDestroyStore,
    // view: importerViewStore,
    // importer: importerImporterStore,
  },
};
