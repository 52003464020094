import importerStore from '@/shared/importer/importer-store';
import { SparePartService } from '@/modules/sparePart/sparePart-service';
import sparePartImporterFields from '@/modules/sparePart/sparePart-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  SparePartService.import,
  sparePartImporterFields,
  i18n('entities.sparePart.importer.fileName'),
  i18n('entities.sparePart.importer.hint'),
);
