import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/vueI18n';

const HomePage = () =>
  import('@/modules/home/components/home-page.vue');

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage,
        exact: true,
        meta: { auth: true },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.home') 
          next();
        },
      },
    ],
  },
];
