import { i18n } from '@/i18n';
import {complaintService} from '@/modules/complaint/complaint-service'
import Message from '@/shared/message/message';
import firebase from 'firebase/app'
import 'firebase/firestore'
import { firestoreAction } from 'vuexfire';


export default {
    namespaced: true,
    state:{
        complaintsList:{},
        complaintsListNEW:{},
        complaintsList2:[],
        complaintsArr:[],
        complaintsArrNEW:[],
        messages:{},
        // messagesNEW:{},
        messages2:[],
        messagesNEW:[],
        selectedId:null,
        selectedMessages: [],
        complaintUser: {},
        loading:false,
        quantity: []
    },
    getters:{
        complaintsList: (state) => state.complaintsList,
        complaintsArr: (state) => state.complaintsArr,
        messages: (state) => state.messages2,
        messages2: (state) => state.messages2,
        messagesNEW: (state) => state.messagesNEW,
        test: (state) => state.test,
        selectedMessages: (state) => state.selectedMessages,
        complaintUser: (state) => state.complaintUser,
        loading: (state) => state.loading,
        quantity: (state) => state.quantity,
    },
    mutations:{
        APPEND_TO_COMPLAINT_LIST(state,payload){
            state.complaintsList[payload.id] = {...payload,messages:{}}
            state.complaintsList2 = state.complaintsList2.filter(I => I.id !== payload.id)
            state.complaintsList2.push(payload)
            state.complaintsArr = []
            Object.keys(state.complaintsList).forEach(key=>{
                state.complaintsArr.push(state.complaintsList[key])
            })
        },
        APPEND_MESSAGE_TO_COMPLAINT_LIST(state,payload){
            state.complaintsList[payload.id].messages[payload.message.id] = payload.message
            state.messages[payload.id] = []
            Object.keys(state.complaintsList[payload.id].messages).forEach(key=>{
                state.messages[payload.id] = state.messages[payload.id].filter(I => I.id !== state.complaintsList[payload.id].messages[key].id)
                state.messages[payload.id].push(state.complaintsList[payload.id].messages[key])
                state.messages2 = state.messages2.filter(I => I.id !== key)
                state.messages2.push({complaintId:payload.id,...state.complaintsList[payload.id].messages[key]})
            })
        },
        CLEAR_COMPLAINT_LIST(state){
            state.complaintsList = {}
        },
        SET_ID(state,payload){
            state.selectedId = payload
        },
        APPEND_TO_MESSAGES(state,payload){
            state.messages2.forEach(element => {
                element.complaintId=payload
                state.messagesNEW.push(element)
                
            });
        },

        APPEND_REPLY(state,payload){
            payload.message.complaintId=payload.id
            state.messagesNEW.push(payload.message)     
        },
        ADD_USER(state,index){
            state.complaintsArr[index].createdBy=state.complaintUser
        },
        ADD_MESSAGE_USER(state,index){
            state.messagesNEW[index].senderId=state.messageUser
        },
        RESET_MESSAGES_AND_COMPLAINTS(state){
            state.messagesNEW= [];
            state.complaintsArr=[]
        },
        START_LOADING(state){
            state.loading= true;
        },
        FINISH_LOADING(state){
            state.loading= false;
        },
    },
    actions:{
        async close({commit},{id,value}){
            console.log(commit);
            await complaintService.close(id,value)
            Message.success(i18n('entities.complaint.success'))
        },

        setComplaintListener : firestoreAction( async ({bindFirestoreRef,commit,getters},status) => {
            commit('RESET_MESSAGES_AND_COMPLAINTS')
            commit('START_LOADING')
            const queryComplaintsArray= firebase.firestore().collection('complaint').orderBy("updatedAt", "desc").where('status','==',status)
            await bindFirestoreRef('complaintsArr', queryComplaintsArray,);
            getters.complaintsArr.forEach(async (complaint) =>{
                const createdBy = await firebase.firestore().collection('user').doc(complaint.createdBy).get()
                complaint['createdBy'] = createdBy.data()
            })
            commit('FINISH_LOADING')
        }),

        setMessagesListener : firestoreAction( async ({bindFirestoreRef,commit},complaintId) => {
            commit('START_LOADING')
            const queryComplaintMessage = firebase.firestore().collection('complaint').doc(complaintId).collection('message')
            await bindFirestoreRef('messagesNEW', queryComplaintMessage);
            // getters.messagesNEW.forEach(async (message) =>{
            //     const senderId = await firebase.firestore().collection('user').doc(message.senderId).get()
            //     message['senderId'] = senderId.data()
            //     console.log(message);
            // })
            commit('FINISH_LOADING')
        }),
        // setQuantity : firestoreAction( async ({bindFirestoreRef,getters}) => {
        //     const queryComplaintMessage = firebase.firestore().collection('items').where('itemId','==','6STAGE').where('status','==','inStock')
        //     await bindFirestoreRef('quantity', queryComplaintMessage);
        //     const length = getters.quantity.length
        //     debugger
        //     firebase.firestore().collection('product').doc('6STAGE').update({
        //         quantity: length
        //       })
        // }),

        doFetchComplaintUser : firestoreAction( async ({getters}) => {
                if(getters.loading==false){
                   const complaint = getters.complaintsArr.at(0);
                const createdBy = await firebase.firestore().collection('user').doc(complaint.createdBy).get()
                complaint['createdBy'] = createdBy.data() 
                } 
        }),

        doFetchMessageUser : firestoreAction( async ({getters}) => {
                if(getters.loading==false){
                  const message = getters.messagesNEW.at(-1);
                const senderId = await firebase.firestore().collection('user').doc(message.senderId).get()
                message['senderId'] = senderId.data()   
                } 
        }),

        // doReply({commit,rootGetters},{data}){
        //      var id = firebase.firestore().collection('ids').doc().id
        //      var obj = {
        //          id:data.id,
        //          message: {
        //             //  id: id,
        //              complaintId:data.id,
        //              senderId:rootGetters['auth/currentUser'].id,
        //              sentAt: firebase.firestore.Timestamp.now(),
        //              ...data
        //             }
        //         }
        //         obj.message.id = id
        //         console.log(obj);
        //      commit('APPEND_REPLY',obj)
        //     complaintService.doReply({...data,mid:id})
        // },

        doReply: firestoreAction ( ({rootGetters},{data}) => {
            var id = firebase.firestore().collection('ids').doc().id
            var obj = {
                id: data.id,
                message: {
                    senderId:rootGetters['auth/currentUser'].id,
                    sentAt: firebase.firestore.Timestamp.now(),
                    complaintId: data.id,
                    id: id,
                    ...data
                   }
               }
               obj.message.id = id
               firebase.firestore().collection('complaint').doc(data.id).update({
                status: 'opened',
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
              })
               firebase.firestore().collection('complaint').doc(data.id).collection('message').doc(id).set({
               ...obj.message
              })
            // commit('APPEND_REPLY',obj)
       }),
        doSendNotification({commit},{data}){
            console.log(commit);
            complaintService.doSendNotification(data)
        },
        setId({commit},id){
            commit("SET_ID",id)
        }
    },
  };