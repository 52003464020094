import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase/app';
import 'firebase/firestore'
// import { storeAsync } from '@/app-module';

export class CitiesRegionsService {
  static async updateCity(id,values){
    await firebase.firestore().collection('city').doc(id).update({name:values.name})
  }
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
            mutation CITY_CREATE($data: CityInput!) {
              cityCreate(data: $data) {
                id
              }
            }
          `,

      variables: {
        data,
      },
    });

    return response.data.cityCreate;
  }

  static async createRegion(cityId, data) {
    let batch = firebase.firestore().batch();
   
    let docRef = firebase.firestore().doc(`city/${cityId}/regions/${data.name.en.toLowerCase()}`) 
    batch.set(docRef, data);
    
    batch.commit()
  }
  static async updateRegion(cityId, data) {
    let batch = firebase.firestore().batch();
    let oldName = data.oldName
    delete data.oldName
    let docRef = firebase.firestore().doc(`city/${cityId}/regions/${oldName.toLowerCase()}`) 
    batch.set(docRef, data);
    
    batch.commit()
  }
  
  static async listCities() {
    // let response = []
    // // await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true }, async () => {
    //   // const cities = await firebase.firestore().collection('city').get({ source: 'cache' });
    // // firebase.firestore().collection('city').onSnapshot(async () => {
    //   const cities = await firebase.firestore().collection('city').get();
    //   cities.forEach(async (doc) => {
    //     let city = doc.data();
    //     city['id'] = doc.id;
    //     response.push(city);
    //   });
    // // });
    // return response

    const collection = FirebaseRepository.mapCollection(
      await firebase.firestore().collection('city').orderBy('createdAt', 'desc').get()
    )
    const cities = await this.populateAll(collection)
    return cities
  }

  static async listRegions() {
    let response = []
    // await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true }, async () => {
      // const cities = await firebase.firestore().collection('city').get({ source: 'cache' });
    // await firebase.firestore().collection('city').onSnapshot(async () => {
      const cities = await firebase.firestore().collection('city').get();
      cities.forEach(async (doc) => {
        let city = doc.data();
        city['id'] = doc.id;
        // city['regions'] = []
        const regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
        regions.forEach(async (region) => {
          let regionInCity = region.data()
          regionInCity['id'] = region.id;
          regionInCity['city'] = city.name

          response.push(regionInCity);
        })
      });
    // });

    return response
  }
  static async findCity(id){
    if(!id) return
    const doc = (await firebase.firestore().collection('city').doc(id).get())
    if(doc.exists){
      return doc.data()
    }
  }
  static async findRegion({id,city}) {
    let response = {}
      const cities = await firebase.firestore().collection('city').where('name.en','==',city).get();
      if(cities && cities.docs.length){
        const regions = await firebase.firestore().collection("city").doc(cities.docs[0].id).collection("regions").doc(id).get()
        if(regions.exists){
          response =  regions.data()
        }
      }
    return response
  }

  static async listCitiesAndRegions() {
    let response = []
    // await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true }, async () => {
      // const cities = await firebase.firestore().collection('city').get({ source: 'cache' });
    // await firebase.firestore().collection('city').onSnapshot(async () => {
      const cities = await firebase.firestore().collection('city').get();
      cities.forEach(async (doc) => {
        let city = doc.data();
        city['id'] = doc.id;
        city['regions'] = []
        let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
        regions.forEach(async (region) => {
          let regionInCity = region.data()
          regionInCity['id'] = region.id;

          city['regions'].push(regionInCity)
          // regionInCity['city'] = city.name

          // this.regions.push(regionInCity)
        })

        response.push(city);
      });
    // });

    return response
  }

  // static async getAllCities() {

  //   let collection = firebase.firestore().doc(`city`).get 
  //   await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true }, async () => {
  //     this.cities = []
  //     const cities = await firebase.firestore().collection("city").get({source: 'cache'})
  //     cities.forEach(async (doc) => {
  //       let city = doc.data()
  //       city['id'] = doc.id
  //       // city['regions'] = []
  //       // let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
  //       // regions.forEach(async (region) =>{
  //       //   let regionInCity = region.data()
  //       //   city['regions'].push(regionInCity)
  //       //   regionInCity['city'] = city.name
  //       //   this.regions.push(regionInCity)

  //       // })

  //       this.cities.push(city)
  //     })
   
      
  //     this.loading = false
  //  })
  // }


//#region [ Helper Functions ]
  /**
   * Populates the records with all its relations.
   * @param {*} records
   */
  static async populateAll(records) {
    return await Promise.all(
      records.map((record) => this.populate(record)),
    );
  }

  /**
   * Populates the record with all its relations.
   * @param {*} record
   */
  static async populate(record) {
    if (!record) {
      return record;
    }
    record['country'] = await FirebaseRepository.findRelation(
      'country', 
      record.countryId
    )

    // const regions = await this.getRegions(record.id)
    // const newRegions = []
    // regions.forEach(reg => {
    //   const newReg = new Region().cast(reg)
    //   newRegions.push(newReg)
    // })
    // record.regions = newRegions
    return record;
  }
//#endregion
}