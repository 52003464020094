import activationListStore from '@/modules/activation/activation-list-store';
import activationFormStore from '@/modules/activation/activation-form-store';
// import activationViewStore from '@/modules/activation/activation-view-store';
// import activationActivationStore from '@/modules/activation/activation-activation-store';
import activationDestroyStore from '@/modules/activation/activation-destroy-store';

export default {
  namespaced: true,

  modules: {
    form: activationFormStore,
    list: activationListStore,
    destroy: activationDestroyStore,
    // view: activationViewStore,
    // activation: activationActivationStore,
  },
};
