import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ActivationListPage = () => 
  import('@/modules/activation/components/activation-list-page.vue')  
// const ActivationFormPage = () => 
//   import('@/modules/activation/components/1-activation-form-page.vue')  
// const ActivationScanPage = () => 
//   import('@/modules/activation/components/1-activation-scan-page.vue') 
const ActivationViewPage = () =>
  import('@/modules/activation/components/activation-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'activation',
        path: '/activation',
        component: ActivationListPage,
        meta: {
          auth: true,
          permission: Permissions.values.activationRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.activation.label') 
          next();
        },
      },
      {
        name: 'activationView',
        path: '/activation/:id',
        props: true,
        component: ActivationViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.activationRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.activation.label') +
            ' | ' +
            i18n('entities.activation.view.title') 
          next();
        },
      },
      // {
      //   name: 'activationNew',
      //   path: '/activation/new',
      //   component: ActivationFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.activationCreate,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title =
      //       i18n('app.title') +
      //       ' | ' +
      //       i18n('entities.activation.label')  +
      //       ' | ' +
      //       i18n('entities.activation.new.title') 
      //     next();
      //   },
      // },
      // {
      //   name: 'activationScan',
      //   path: '/activation/scan',
      //   // props: true,
      //   component: ActivationScanPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.activationCreate,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title =
      //       i18n('app.title') +
      //       ' | ' +
      //       i18n('entities.activation.label')  +
      //       ' | ' +
      //       i18n('entities.activation.pleaseScanItems') 
      //     next();
      //   },
      // },
    ],
  },
];



