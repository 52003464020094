import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const SparePartListPage = () =>
  import('@/modules/sparePart/components/sparePart-list-page.vue');
const SparePartFormPage = () =>
  import('@/modules/sparePart/components/sparePart-form-page.vue');

const SparePartExportPage = () => 
  import('@/modules/sparePart/components/sparePart-export-page.vue')  
// const SparePartViewPage = () =>
//   import('@/modules/sparePart/components/sparePart-view-page.vue');
// const SparePartImporterPage = () =>
//   import('@/modules/sparePart/components/sparePart-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'sparePart',
        path: '/spare-part',
        component: SparePartListPage,
        meta: {
          auth: true,
          permission: Permissions.values.sparePartView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.spareParts') 
          next();
        },
      },
      {
        name: 'sparePartNew',
        path: '/spare-part/new',
        component: SparePartFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.sparePartCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.spareParts') 
          next();
        },
      },
      {
        name: 'sparePartEdit',
        path: '/spare-part/:id/edit',
        component: SparePartFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.sparePartEdit,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.spareParts') 
          next();
        },
      },
      {
        name: 'sparePartExport',
        path: '/spare-part/:id/export',
        component: SparePartExportPage,
        meta: {
          auth: true,
          // permission: Permissions.values.sparePartEdit,
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.spareParts') 
          next();
        },
      },
      // {
      //   name: 'sparePartImporter',
      //   path: '/spare-part/import',
      //   component: SparePartImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sparePartImport,
      //   },
      // },
      // {
      //   name: 'sparePartView',
      //   path: '/spare-part/:id',
      //   component: SparePartViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sparePartView,
      //   },
      //   props: true,
      // },
    ],
  },
];
