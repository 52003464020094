//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/firestore'
// import moment from 'moment';

export default {
  name: 'app-layout',

  async created(){
    // let counter = 0
    // firebase.firestore().collection('items').where('status','==','inStock').get().then(res => {
    //   res.docs.forEach(doc => {
    //     if(moment().diff(moment(doc.data().createdAt.seconds,'X'),'days') >= 0 &&
    //     moment().diff(moment(doc.data().createdAt.seconds,'X'),'days') < 3){
    //       console.log(moment().diff(moment(doc.data().createdAt.seconds,'X'),'days'));
    //       counter++;
    //       console.log(counter);
    //       doc.ref.update({status:'inFactory'})
    //     }
    //   })
    //   console.log();
    //   console.log(`not exported items = ${res.docs.length} .. size of response ${res.size}`);
    // })
    // let arr = ['114148883932']
    // let x = []
    // let c = 0
    // arr.forEach(async a => {
    //   let y = (await firebase.firestore().collection('items').doc(a).get()).data()
    //   let b = (await firebase.firestore().collection('product').doc(y.itemId).get()).data()
    //   y.itemDetails = b
    //   x.push(y)
    //   c = c + 1
    //   if(c === arr.length){
    //     let i = {sellerId:'Whyj5Ex2JqjnDNHXDQDy',
    //       name: 'سما البركة',
    //       phone: '01205666600',
    //       items: x,
    //       createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //       createdBy: this.currentUser.id
    //     }
    //     firebase.firestore().collection('bills').add(i).then(res => {
    //       console.log(res);
    //     }).catch(err=> {
    //       console.log(err);
    //     })
    //   }
    // })

    // firebase.firestore().collection('items').onSnapshot({includeMetadataChanges:true},async snap => {
  //   console.log('snap meta' , snap.metadata.fromCache?'cache':'server');
  // //   this.doFetch({ filter });
  // //   // this.doFetchEx()
  // })
    // this.productListener()
    // firebase.firestore().collection('items').onSnapshot({includeMetadataChanges:true},async snap => {
    //   console.log('snap meta' , snap.metadata.fromCache?'cache':'server');
    //   let filter = {}
    //   filter['status'] = 'inStock'
    //   this.doFetch({ filter });
    //   // this.doFetchEx()
    // })

    // Listening on Settings Is Modified
    this.unsubscribe1 = await firebase.firestore().collection('settings').onSnapshot(async () => {
      //  console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFindSettings()
    }, err => {
       console.log(`Encountered error: ${err}`);
    });

    // Listening on CurrentUser Is Modified
    this.unsubscribe2 = await firebase.firestore().collection('user').where('id', '==', this.currentUser.id).onSnapshot(async snap => {
      // console.log(`User Size = ${snap.size}`);
      // this.doRefreshCurrentUser()

      snap.docChanges().forEach(change => {
        if (change.type === 'modified') {
          // console.log('Modified To CurrentUser: ', change.doc.data());
          this.doRefreshCurrentUser()
        }
        // if (change.type === 'added') {
        //   console.log('Added To CurrentUser: ', change.doc.data());
        // }
        // if (change.type === 'removed') {
        //   console.log('Removed From CurrentUser: ', change.doc.data());
        // }
      });
    })
  },
  beforeDestroy(){
    if(this.unsubscribe1) this.unsubscribe1()
    if(this.unsubscribe2) this.unsubscribe2()
  },
  data() {
    return {
      unsubscribe1: undefined,
      unsubscribe2: undefined,
      drawer: false,
      left: false,
      miniState: false,
      
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      settings:'settings/settings',
    }),

    // asideWidth() {
    //   if (!this.collapsed) {
    //     return 'margin-left: 220px;';
    //   }
    //   return 'margin-left: 55px;';
    // },
    asideWidth() {
      if (!this.isMobile ) {
        return 220;
      }
      return 200;
    },
    currentBrowser() {
      return localStorage.getItem('browser')
    }
  },
  methods: {
    ...mapActions({
      initChat: 'initChat',
      setOtherUserID: 'setOtherUserID',
      doLogoutChat: 'auth/doLogoutChat',
      productListener:'layout/productListener',
      doFindSettings:'settings/doFind',
      doFetch: 'importer/list/doFetch',
      doRefreshCurrentUser: 'auth/doRefreshCurrentUser'
    }),

    drawerClick(e) {
      // if in "mini" state and user
      // click on drawer, we switch it to "normal" mode
      if (this.miniState) {
        this.miniState = false;
        // notice we have registered an event with capture flag;
        // we need to stop further propagation as this click is
        // intended for switching drawer to "normal" mode only
        e.stopPropagation();
      }
    },
  },
};
