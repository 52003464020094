import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class SparePartPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.sparePartRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.sparePartImport,
    );
    this.export = permissionChecker.match(
      Permissions.values.sparePartExport,
    );
    this.sparePartAutocomplete = permissionChecker.match(
      Permissions.values.sparePartAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.sparePartCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.sparePartEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.sparePartDestroy,
    );
  }
}
