import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const RestoreListPage = () => 
  import('@/modules/restore-items/components/restore-list-page.vue')  
// const RestoreFormPage = () => 
//   import('@/modules/restore-items/components/1-restore-form-page.vue')  
const RestoreScanPage = () => 
  import('@/modules/restore-items/components/1-restore-scan-page.vue') 

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'restore',
        path: '/restore',
        component: RestoreListPage,
        meta: {
          auth: true,
          permission: Permissions.values.restoreRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.restore.label') 
          next();
        },
      },
      // {
      //   name: 'restoreNew',
      //   path: '/restore/new',
      //   component: RestoreFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.restoreCreate,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title =
      //       i18n('app.title') +
      //       ' | ' +
      //       i18n('entities.restore.label')  +
      //       ' | ' +
      //       i18n('entities.restore.new.title') 
      //     next();
      //   },
      // },
      {
        name: 'restoreScan',
        path: '/restore/scan',
        // props: true,
        component: RestoreScanPage,
        meta: {
          auth: true,
          permission: Permissions.values.restoreCreate,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.restore.label')  +
            ' | ' +
            i18n('entities.restore.pleaseScanItems') 
          next();
        },
      },
    ],
  },
];



