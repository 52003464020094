import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-importer';
import firebase from 'firebase/app'
import 'firebase/firestore'
import FirebaseQuery from '@/shared/firebase/firebaseQuery'
import { storeAsync } from '@/app-module';
// import Message from '@/shared/message/message';

export class RestoreService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_UPDATE(
          $id: String!
          $data: ImporterInput!
        ) {
          importerUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.importerUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_DESTROY($ids: [String!]!) {
          importerDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.importerDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_CREATE($data: ImporterInput!) {
          importerCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.importerCreate;
  }

  static async importer(values, importerHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation IMPORTER_IMPORT(
          $data: ImporterInput!
          $importerHash: String!
        ) {
          importerImporter(data: $data, importerHash: $importerHash)
        }
      `,

      variables: {
        data: values,
        importerHash,
      },
    });

    return response.data.importerImporter;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query IMPORTER_FIND($id: String!) {
          importerFind(id: $id) {
            id
            serialNumber
            qrCode
            itemId              
            itemType
            status
            warrantyStatus
            activationDate
            expirationDate
            itemDetails {
              name
              brand
              features
              barcode
              qrCode
              quantity
              warrantyPeriod
              photo {
                id
                name
                publicUrl
              }
            }
            seller {
              name
              address
              telephone
              manager {
                name
                phoneNumber
              }
              isOnlineStore
              websiteUrl
              taxFacilityNumber
              countryId
              cityId
              regionId
              sellerType
            }
            exportedBy
            exportedAt
            activatedBy {
              id
              fullName
              phoneNumber
              userType
            }
            isRestored
            restoredBy{
              id
              fullName
              phoneNumber
              userType
            }
            restoredAt
            notes

            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });
    return response.data.importerFind;
  }

  static filter(rows,filter, orderBy, limit, offset){
    console.log(filter, orderBy, limit,offset);
    const query = FirebaseQuery.forList({
      orderBy: orderBy || 'createdAt_DESC',
    });  
    if (filter) {
      if (filter.id) {
        query.appendId('id', filter.id);
      }
      if (filter.serialNumber) {
        query.appendEqual('serialNumber', filter.serialNumber);
      }
      if (filter.qrCode) {
        query.appendEqual('qrCode', filter.qrCode);
      }
      if (filter.itemId) {
        query.appendEqual('itemId', filter.itemId);
      }
      if (filter.itemType) {
        query.appendEqual('itemType', filter.itemType);
      }
      if (filter.status) {
        query.appendEqual('status', filter.status);
      }
      if (filter.warrantyStatus) {
        query.appendEqual('warrantyStatus', filter.warrantyStatus);
      }

      if (filter.activationDate) {
        query.appendEqual('activationDate', filter.activationDate);
      }
      if (filter.expirationDate) {
        query.appendEqual('expirationDate', filter.expirationDate);
      }
      if (filter.createdAt) {
        query.appendEqual('createdAt', filter.createdAt);
      }

      if (filter.exportedBy) {
        query.appendEqual('exportedBy', filter.exportedBy);
      }
      if (filter.exportedAt) {
        query.appendEqual('exportedAt', filter.exportedAt);
      }
      if (filter.exportedAtRange) {
        query.appendRange(
          'exportedAt',
          filter.exportedAtRange,
        );
      }

      if (filter.activationDateRange) {
        query.appendRange(
          'ctivationDate',
          filter.activationDateRange,
        );
      }
      if (filter.expirationDateRange) {
        query.appendRange(
          'expirationDate',
          filter.expirationDateRange,
        );
      }
      if (filter.createdAtRange) {
        query.appendRange(
          'createdAt',
          filter.createdAtRange,
        );
      }
    }
    rows = query.rows(rows)
    let count = query.count(rows)
    return {rows, count}
  }

  static async getFromCache(collectionName){
    let res;
    try {
      res = await firebase.firestore().collection(collectionName).get({source : "cache"})
      if(!res.docs.length){
        res = await firebase.firestore().collection(collectionName).get()
      }
    } catch (error) {
      res = await firebase.firestore().collection(collectionName).get()
    }
    if(res){
      console.log(`${collectionName} response came from ${res.metadata.fromCache ? 'CACHE': 'SERVER'} with size ${res.size}`);
      return res
    }
    else return null
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query IMPORTER_LIST(
          $filter: ImporterFilterInput
          $orderBy: ImporterOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          importerList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              serialNumber
              qrCode
              itemId              
              itemType
              status
              warrantyStatus
              activationDate
              expirationDate
              itemDetails {
                name
                brand
                features
                barcode
                qrCode
                quantity
                warrantyPeriod
                photo {
                  id
                  name
                  publicUrl
                }
              }
              seller {
                name
                address
                telephone
                manager {
                  name
                  phoneNumber
                }
                isOnlineStore
                websiteUrl
                taxFacilityNumber
                countryId
                cityId
                regionId
                sellerType
              }
              exportedBy
              exportedAt
              activatedBy {
                id
                fullName
                phoneNumber
                userType
              }
              isRestored
              restoredBy{
                id
                fullName
                phoneNumber
                userType
              }
              restoredAt
              notes

              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });
    return response.data.importerList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query IMPORTER_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          importerAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.importerAutocomplete;
  }

  static async getBills(callback) {
    firebase.firestore().collection('bills').onSnapshot({includeMetadataChanges:true},async () => {
      const data = await (await firebase.firestore().collection('bills').orderBy('createdAt', 'desc').get({source:"cache"})).docs.map(I => {
        return {
          id: I.id,
          ...I.data()
        }
      })
      callback(data)

    })
    // return data
  }

  static async restoreItemsByTransaction(notes, data) {
    try {
      const res = await firebase.firestore().runTransaction(async (t) => {
        const currentUser = storeAsync().getters['auth/currentUser']
        // Get All Bills
        const billsRef = firebase.firestore().collection('bills')
        // const collection = await t.get(billsRef)
        const collection = await billsRef.get()
        const bills = this.mapCollection(collection)
        console.log(bills);

        // 1- Restore items to stock. 
        // 2- Increment quantity in category.
        // 3- Delete Items Form Bills. 
        data.forEach(item => {
          // [1] Restore this item to stock
          const docRef = firebase.firestore().collection('items').doc(item.id)
          t.update(docRef, {
            status: "inStock",
            warrantyStatus: "inactive",
            activationDate: null,
            expirationDate: null,
            activatedBy: null,
            sellerId: null,
            exportedBy: null,
            exportedAt: null,
            notes: notes,
            isRestored: true,
            restoredBy: currentUser.id,
            restoredAt: firebase.firestore.FieldValue.serverTimestamp(),
          });

          // [2] Increment quantity in category
          this.incrementQuantityByFieldValue(item.itemType, item.itemId, 1, t)

          // [3] Delete Item Form Bills.
          const billDoc = bills.filter(el => (el.sellerId == item.sellerId && el.items.find(i => i['id'] === item.id))) 
          if (billDoc.length) {
            let list = billDoc[0].items
            const index = list.findIndex(el => el.id == item.id)
            list.splice(index, 1);

            const billRef = firebase.firestore().collection('bills').doc(billDoc[0].id)
            t.update(billRef, {
              items: list
            })
          }
        });
      });

      console.log('Transaction success!', res);
    } catch (e) {
      console.log('Transaction failure:', e);
      // const error = { customError: { message: e } };
      throw e
    }
  }

  static async updateQuantityByTransaction(collectionName, docId, quantity) {
    try {
      let docRef = firebase.firestore().collection(collectionName).doc(docId)
      await firebase.firestore().runTransaction(async (t) => {
        const doc = await t.get(docRef);
        const newQuantity = doc.data().quantity + quantity;
        t.update(docRef, {
          quantity: newQuantity
        });
      });

      console.log('Transaction success!');
    } catch (e) {
      console.log('Transaction failure:', e);
    }
  }

  static async incrementQuantityByFieldValue(collectionName, docId, quantity, batch) {
    try {
      const increment = firebase.firestore.FieldValue.increment(quantity);
      // Document reference
      const docRef = firebase.firestore().collection(collectionName).doc(docId)

      if (batch) {
        batch.update(docRef, { quantity: increment });
      } else {
        // Update quantity 
        docRef.update({
          quantity: increment
        });
      }

      console.log('Increment quantity success!');
    } catch (e) {
      console.log('Increment quantity failure:', e);
    }
  }

  static async decrementQuantityByFieldValue(collectionName, docId, quantity) {
    try {
      const decrement = firebase.firestore.FieldValue.increment(-quantity);

      // Document reference
      const docRef = firebase.firestore().collection(collectionName).doc(docId)

      // Update quantity 
      docRef.update({
        quantity: decrement
      });

      console.log('Decrement quantity success!');
    } catch (e) {
      console.log('Decrement quantity failure:', e);
    }
  }

  /**
   * Maps collection documents.
   * Adds the ID and replaces timestamps to date.
   */
  static mapCollection(collection) {
    if (collection.empty) {
      return [];
    }

    const list = [];

    collection.forEach((document) => {
      const item = Object.assign({}, document.data(), {
        id: document.id,
      });

      this.replaceAllTimestampToDate(item);
      list.push(item);
    });

    return list;
  }

  /**
   * Replaces all Firestore timestamps to Date.
   */
  static replaceAllTimestampToDate(arg) {
    if (!arg) {
      return arg;
    }

    Object.keys(arg).forEach((key) => {
      if (
        arg[key] &&
        arg[key] instanceof firebase.firestore.Timestamp
      ) {
        arg[key] = arg[key].toDate();
      }
    });
  }
}