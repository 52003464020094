import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-seller';
import firebase from 'firebase/app';
import 'firebase/firestore'
// import 'firebase/firebase-firestore';

// import { storeAsync } from '@/app-module';
// import FirebaseQuery from '@/shared/firebase/firebaseQuery'

export class ApplicationService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation APPLICATION_UPDATE(
          $id: String!
          $data: ApplicationInput!
        ) {
          applicationUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.applicationUpdate;
  }
  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation APPLICATION_DESTROY($ids: [String!]!) {
          applicationDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.applicationDestroy;
  }

  static async rejectAll(ids) {
    let batch = firebase.firestore().batch();

    for (let index = 0; index < ids.length; index++) {
      const docRef = firebase.firestore().collection('application').doc(ids[index])
      batch.update(docRef, {
        status: 'rejected'
      });
    }
    
    batch.commit()
  }
  static async contactAll(ids) {
    let batch = firebase.firestore().batch();

    for (let index = 0; index < ids.length; index++) {
      const docRef = firebase.firestore().collection('application').doc(ids[index])
      batch.update(docRef, {
        status: 'contacted'
      });
    }
    
    batch.commit()
  }
  

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation APPLICATION_CREATE($data: ApplicationInput!) {
          applicationCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.applicationCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation APPLICATION_IMPORT(
          $data: ApplicationInput!
          $importHash: String!
        ) {
          applicationImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.applicationImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query APPLICATION_FIND($id: String!) {
          applicationFind(id: $id) {
            id
            name
            phoneNumber
            email
            city
            distributorType
            status
            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.applicationFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query APPLICATION_LIST(
          $filter: ApplicationFilterInput
          $orderBy: ApplicationOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          applicationList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              name
              phoneNumber
              email
              city
              distributorType
              status
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.applicationList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query APPLICATION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          applicationAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.applicationAutocomplete;
  }

  static mapCollection(collection) {
    if (collection.empty) {
      return [];
    }

    const list = [];

    collection.forEach((document) => {
      const item = Object.assign({}, document.data(), {
        id: document.id,
      });

      // this.replaceAllTimestampToDate(item);
      list.push(item);
    });

    return list;
  }
  static _getRandomInt(min, max) {
    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  }
}