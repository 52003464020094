import sparePartListStore from '@/modules/sparePart/sparePart-list-store';
import sparePartViewStore from '@/modules/sparePart/sparePart-view-store';
import sparePartImporterStore from '@/modules/sparePart/sparePart-importer-store';
import sparePartFormStore from '@/modules/sparePart/sparePart-form-store';
import sparePartDestroyStore from '@/modules/sparePart/sparePart-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: sparePartDestroyStore,
    form: sparePartFormStore,
    list: sparePartListStore,
    view: sparePartViewStore,
    importer: sparePartImporterStore,
  },
};
