import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import ImagesField from '@/shared/fields/images-field';
import IntegerField from '@/shared/fields/integer-field';

function label(name) {
  return i18n(`entities.sparePart.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), { "required": true }),
  brand: new StringField('brand', label('brand'), { "required": true }),
  features: new StringField('features', label('features'), {}),
  barcode: new StringField('barcode', label('barcode'), {}),
  quantity: new IntegerField('quantity', label('quantity'), { "min": 0 }),
  warrantyPeriod: new IntegerField('warrantyPeriod', label('warrantyPeriod'), { "min": 0 }),
  photo: new ImagesField('photo', label('photo'), 'product/photos', {
    "max": 1,
    "size": 1024000
  }),
  qrCode: new StringField('qrCode', label('qrCode'), {}),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')), 
  createdAtRange: new DateTimeRangeField( 'createdAtRange', label('createdAtRange')),
};

export class SparePartModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
