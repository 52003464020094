//
//
//
//
//
//
//
//
//
//
//
//
//

// import { I18nUtil } from '@/shared/i18n/i18n-util';
import i18n from '@/vueI18n';
import { getLanguages } from '@/i18n';
import { Quasar } from 'quasar'

export default {
  name: 'app-i18n-flags',

  computed: {
    languages() {
      return getLanguages();
    },
  },

  methods: {
    doChangeLanguage(language) {
      // if(language != localStorage.getItem('language')) {
      //   I18nUtil.doChangeLanguage(language);
      // }
      i18n.locale = language;
      localStorage.setItem('language', language);
      const { lang } = this.$route.params;
      let route = '';
      if (lang && (lang === 'ar' || lang === 'en')) {
        route = `/${language}${this.$route.fullPath.substring(
          3,
        )}`;
      } else {
        route = `/${language}${this.$route.fullPath}`;
      }
      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      console.log(route)
    },
    // doChangeLanguage(language) {
    //   I18nUtil.doChangeLanguage(language);
    // },
  },
};
