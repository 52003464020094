import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-activation';
import firebase from 'firebase/app'
import 'firebase/firestore';

// import FirebaseQuery from '@/shared/firebase/firebaseQuery'
// import { storeAsync } from '@/app-module';
// import Message from '@/shared/message/message';

export class ActivationService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation ACTIVATION_UPDATE(
          $id: String!
          $data: ActivationInput!
        ) {
          activationUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.activationUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation ACTIVATION_DESTROY($ids: [String!]!) {
          activationDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.activationDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation ACTIVATION_CREATE($data: ActivationInput!) {
          activationCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.activationCreate;
  }

  static async importer(values, activationHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation ACTIVATION_IMPORT(
          $data: ActivationInput!
          $activationHash: String!
        ) {
          activationActivation(data: $data, activationHash: $activationHash)
        }
      `,

      variables: {
        data: values,
        activationHash,
      },
    });

    return response.data.activationActivation;
  }

  static async markAsRead(ids) {
    ids.forEach(id => {
      firebase.firestore().collection('activation').doc(id).update({ unread: false })
    });
  }

  static async markAsUnread(ids) {
    ids.forEach(id => {
      firebase.firestore().collection('activation').doc(id).update({ unread: true })
    });
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
        query ACTIVATION_FIND($id: String!) {
          activationFind(id: $id) {
            id
            unread
            showAlert
            limitExceeded
            activatedBy 
            activator {
              id
              fullName
              phoneNumber
              email
              roles
              disabled
              deviceTokens
              userType
              avatars {
                publicUrl
              }
            }
            items {
              id
              serialNumber
              photo
              qrCode
              itemId              
              itemType
              status
              warrantyStatus
              activationDate
              expirationDate
              itemDetails {
                name
                brand
                features
                barcode
                warrantyPeriod
              }
              sellerId
              seller {
                id
                name
                address
                telephone
                manager {
                  name
                  phoneNumber
                }
                taxFacilityNumber
                countryId
                cityId
                regionId
                sellerType
              }
            }

            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });
    return response.data.activationFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query ACTIVATION_LIST(
          $filter: ActivationFilterInput
          $orderBy: ActivationOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          activationList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              unread
              showAlert
              limitExceeded
              activatedBy 
              activator {
                id
                fullName
                phoneNumber
                email
                roles
                disabled
                deviceTokens
                userType
                avatars {
                  publicUrl
                }
              }
              items {
                id
                serialNumber
                photo
                qrCode
                itemId              
                itemType
                status
                warrantyStatus
                activationDate
                expirationDate
                itemDetails {
                  name
                  brand
                  features
                  barcode
                  warrantyPeriod
                }
                sellerId
                seller {
                  id
                  name
                  address
                  telephone
                  manager {
                    name
                    phoneNumber
                  }
                  taxFacilityNumber
                  countryId
                  cityId
                  regionId
                  sellerType
                }
              }

              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });
    return response.data.activationList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query ACTIVATION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          activationAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.activationAutocomplete;
  }

  /**
   * Maps collection documents.
   * Adds the ID and replaces timestamps to date.
   */
  static mapCollection(collection) {
    if (collection.empty) {
      return [];
    }

    const list = [];

    collection.forEach((document) => {
      const item = Object.assign({}, document.data(), {
        id: document.id,
      });

      this.replaceAllTimestampToDate(item);
      list.push(item);
    });

    return list;
  }

  /**
   * Replaces all Fiactivation timestamps to Date.
   */
  static replaceAllTimestampToDate(arg) {
    if (!arg) {
      return arg;
    }

    Object.keys(arg).forEach((key) => {
      if (
        arg[key] &&
        arg[key] instanceof firebase.fiactivation.Timestamp
      ) {
        arg[key] = arg[key].toDate();
      }
    });
  }
}