import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class AccessoryPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.accessoryRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.accessoryImport,
    );
    this.accessoryAutocomplete = permissionChecker.match(
      Permissions.values.accessoryAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.accessoryCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.accessoryEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.accessoryDestroy,
    );
  }
}
