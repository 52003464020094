import { storeAsync } from '@/app-module';

export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta || !to.meta.auth) {
      next();
      return;
    }

    const store = storeAsync();
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit');
    }
    // await store.dispatch('auth/doWaitUntilInit');
    
    if (!store.getters['auth/signedIn']) {
      // next({ path: '/auth/signin' });
      next({ path: '/auth' });
      return;
    }

    if (
      to.path !== '/auth/email-unverified' &&
      !store.getters['auth/currentUser'].emailVerified
    ) {
      next({ path: '/auth/email-unverified' });
      return;
    }

    if (
      to.path !== '/auth/empty-permissions' &&
      store.getters['auth/currentUser'].emailVerified &&
      !store.getters['auth/roles'].length
    ) {
      next({ path: '/auth/empty-permissions' });
      return;
    }

    next();
  },
};
