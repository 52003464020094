import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-branch';
import * as securePin from "secure-pin";
import firebase from 'firebase/app';
import 'firebase/firestore'
import { storeAsync } from '@/app-module';
// import FirebaseQuery from '@/shared/firebase/firebaseQuery'

export class BranchService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation BRANCH_UPDATE(
          $id: String!
          $data: BranchInput!
        ) {
          branchUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.branchUpdate;
  }
  static async branchCanBeDeleted(id){
    return !!(await firebase.firestore().collection('items').where('branchId','==',id).get()).docs.length
  }
  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation BRANCH_DESTROY($ids: [String!]!) {
          branchDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.branchDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation BRANCH_CREATE($data: BranchInput!) {
          branchCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.branchCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation BRANCH_IMPORT(
          $data: BranchInput!
          $importHash: String!
        ) {
          branchImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.branchImport;
  }

  static async find(id) {
    
    const response = await graphqlClient.query({
      query: gql `
        query BRANCH_FIND($id: String!) {
          branchFind(id: $id) {
            id
            name
            address
            telephone
            manager {
              name
              phoneNumber
            }
            isOnlineStore
            websiteUrl
            taxFacilityNumber
            countryId
            cityId
            regionId
            city
            region
            
            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.branchFind;
  }

  static async list(filter, orderBy, limit, offset,callback) {
    // let city = (await firebase.firestore().collection('city').get()).docs.map(I => I.data())
    // let region = []
    // for (let index = 0; index < city.length; index++) {
    //   const element = city[index];
    //   const regions = (await firebase.firestore().collection('city').doc(element.id).collection('regions').get()).docs.map(I => {
    //     return {
    //       ...I.data(),
    //       id:element.id
    //     }
    //   })
    //   region.push(...regions)
      
    // }
    // firebase.firestore().collection('branch').onSnapshot({includeMetadataChanges:true},async () => {
    //   let rows = (await firebase.firestore().collection('branch').get({source:"cache"})).docs.map(I => {
    //     console.log(region.find(el => el.name.en === I.data().regionId || el.name.ar === I.data().regionId));
    //     return {
    //       ...I.data(),
    //       city: city.find(el => el.id === I.data().cityId),
    //       region: region.find(el => el.name.en === I.data().regionId || el.name.ar === I.data().regionId) || {name:{}},
    //     }
    //   })
    //   const query = FirebaseQuery.forList({
    //     limit,
    //     offset,
    //     orderBy: orderBy || 'createdAt_DESC',
    //   });
  
    //   if (filter) {
    //     if (filter.id) {
    //       query.appendId('id', filter.id);
    //     }
    //     if (filter.name) {
    //       query.appendEqual('name', filter.name);
    //     }
    //     if (filter.address) {
    //       query.appendEqual('address', filter.address);
    //     }
    //     if (filter.telephone) {
    //       query.appendEqual('telephone', filter.telephone);
    //     }
    //     if (filter.isOnlineStore) {
    //       query.appendEqual('isOnlineStore', filter.isOnlineStore);
    //     }
    //     if (filter.websiteUrl) {
    //       query.appendEqual('websiteUrl', filter.websiteUrl);
    //     }
    //     if (filter.taxFacilityNumber) {
    //       query.appendEqual('taxFacilityNumber', filter.taxFacilityNumber);
    //     }
    //     if (filter.regionId) {
    //       query.appendEqual('regionId', filter.regionId);
    //     }
    //     if (filter.countryId) {
    //       query.appendEqual('countryId', filter.countryId);
    //     }
    //     if (filter.cityId) {
    //       query.appendEqual('cityId', filter.cityId);
    //     }
  
    //     if (filter.branchType) {
    //       query.appendEqual('branchType', filter.branchType);
    //     }
        
    //     if (filter.distributorType) {
    //       query.appendEqual('distributorType', filter.distributorType);
    //     }
  
    //     if (filter.createdAtRange) {
    //       query.appendRange(
    //         'createdAt',
    //         filter.createdAtRange,
    //       );
    //     }
    //   }
    //   rows = query.rows(rows)
    //   let count = query.count(rows)
    //   callback({rows,count})
    // })
    const response = await graphqlClient.query({
      query: gql `
        query BRANCH_LIST(
          $filter: BranchFilterInput
          $orderBy: BranchOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          branchList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              name
              address
              telephone
              manager {
                name
                phoneNumber
              }
              isOnlineStore
              websiteUrl
              taxFacilityNumber
              countryId
              cityId
              regionId
              city 
              region
              
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });
    callback({rows:response.data.branchList.rows,count:response.data.branchList.count})
    return response.data.branchList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql `
        query BRANCH_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          branchAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.branchAutocomplete;
  }

  static mapCollection(collection) {
    if (collection.empty) {
      return [];
    }

    const list = [];

    collection.forEach((document) => {
      const item = Object.assign({}, document.data(), {
        id: document.id,
      });

      // this.replaceAllTimestampToDate(item);
      list.push(item);
    });

    return list;
  }
  /**
   * Counts the number of Pin Code.
   *
   * @param {Object} filter
   */
  static async pinCodeCount() {
    let chain = firebase.firestore().collection('pinCode');
    return (await chain.get()).size;
  }
  static async pinCodeList() {
    const collection = await firebase.firestore().collection('pinCode').get();
    return this.mapCollection(collection)
    // collection.forEach(async (doc) => {
    //   let city = doc.data();
    //   city['id'] = doc.id;
    //   response.push(city);
    // });
  } 
  static async pinCodeListByTransaction(t) {
    const collection = await t.collection('pinCode').get();
    return this.mapCollection(collection)
  } 

  static async GenerateAndCreatePinCode() {
    try {
      let currentUser = storeAsync().getters['auth/currentUser']
      await firebase.firestore().runTransaction(async (t) => {
        const pinCodeCollection = await this.pinCodeListByTransaction()
        let limit = pinCodeCollection.length
        let pin 

        while(limit < pinCodeCollection.length + 1 ) {
          pin = securePin.generatePinSync(8)
    
          if (pinCodeCollection.length) {
            if(pinCodeCollection.indexOf(pin) === -1) limit += 1
          } else {
            limit += 1
          }
        }  

        let docRef = firebase.firestore().doc(`pinCode/${pin}`) 
        t.set(docRef, {
          id: pin,
          // pinCode: pin,
          createdBy: currentUser.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });
    
       console.log('Transaction success!');
    } catch (e) {
       console.log('Transaction failure:', e);
    }
  }

  static async pinCodeGenerators(count) {
    try {
      const pinCodeCollection = await this.pinCodeList()
      // let limit = pinCodeCollection.length
      let limit = count
      let pinCodes = []
      
      while(pinCodes.length < limit ) {
        const code = securePin.generatePinSync(8)
  
        if (pinCodeCollection.length) {
          if(pinCodeCollection.indexOf(code) === -1) pinCodes.push(code)
        } else {
          pinCodes.push(code)
        }
      }  

      return pinCodes
    } catch (e) {
      console.log('Error: ', e);
    }
  }

  static async pinCodeGenerator() {
    try {
      const pinCodeCollection = await this.pinCodeList()
      let limit = pinCodeCollection.length
      let pin 
      
      while(limit < pinCodeCollection.length + 1 ) {
        pin = securePin.generatePinSync(8)
  
        if (pinCodeCollection.length) {
          if(pinCodeCollection.indexOf(pin) === -1) limit += 1
        } else {
          limit += 1
        }
      }  

      return pin
    } catch (e) {
       console.log('Error: ', e);
    }
  }
  // static async createPinCode(pinCode) {
  //   let currentUser = storeAsync().getters['auth/currentUser']

  //   let batch = firebase.firestore().batch();
    
  //   let docRef = firebase.firestore().doc(`pinCode/${pinCode}`) 
  //   batch.set(docRef, {
  //     id: pinCode,
  //     // pinCode: pin,
  //     createdBy: currentUser.id,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });
    
  //   await batch.commit()
  // }

  static async createPinCode(data) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`pinCode/${data.pinCode}`) 
    batch.set(docRef, {
      id: data.pinCode,
      distributorType: data.distributorType,
      wholesalerId: data.wholesalerId,
      createdBy: currentUser.id,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    await batch.commit()
  }

  static _getRandomInt(min, max) {
    return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  }

}