import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const Regions = () =>
  import('@/modules/cities/components/import-excel/regions.vue');
const Cities = () =>
  import('@/modules/cities/components/import-excel/cities.vue');
const CitiesRegions = () =>
  import('@/modules/cities/components/import-excel/cities-regions.vue');

const CitiesListPage = () =>
  import('@/modules/cities/components/cities-list-page.vue');
const RegionsListPage = () =>
  import('@/modules/cities/components/regions-list-page.vue');


export default [
    {
      name: '',
      path: '',
      exact: true,
      component: Layout,
      meta: { auth: true },
      children: [
        {
          name: 'cities',
          path: '/cities',
          component: CitiesListPage,
          meta: {
            auth: true,
            permission: Permissions.values.cityRead,
          },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.cities') 
            next();
          },
        },
        {
          name: 'regions',
          path: '/regions',
          component: RegionsListPage,
          meta: {
            auth: true,
            permission: Permissions.values.cityRead,
          },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.regions') 
            next();
          },
        },
        {
          name: 'city',
          path: 'city',
          component: Cities,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.cities') 
            next();
          },
        },
        {
          name: 'region',
          path: 'region',
          component: Regions,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.regions') 
            next();
          },
        },
        {
          name: 'cities-regions',
          path: 'cities-regions',
          component: CitiesRegions,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.cities') 
            next();
          },
        },
      ]
    }
]