import restoreListStore from '@/modules/restore-items/restore-list-store';
import restoreFormStore from '@/modules/restore-items/restore-form-store';
// import restoreViewStore from '@/modules/restore-items/restore-view-store';
// import restoreRestoreStore from '@/modules/restore-items/restore-restore-store';
import restoreDestroyStore from '@/modules/restore-items/restore-destroy-store';

export default {
  namespaced: true,

  modules: {
    form: restoreFormStore,
    list: restoreListStore,
    destroy: restoreDestroyStore,
    // view: restoreViewStore,
    // restore: restoreRestoreStore,
  },
};
