//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-created-item-successfully-page',
  props: ['message'],

  mounted() {
    setTimeout(() => {
      this.$emit('close');
    }, 3000);
  },
};
