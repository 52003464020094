import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';


const DistributorListPage = () =>
  import('@/modules/seller/components/seller-distributor-page.vue');

// const SellerListPage = () =>
//   import('@/modules/seller/components/seller-list-page.vue');
// const SellerFormPage = () =>
//   import('@/modules/seller/components/seller-form-page.vue');

// const SellerExportPage = () => 
//   import('@/modules/seller/components/seller-export-page.vue')  
// const SellerViewPage = () =>
//   import('@/modules/seller/components/seller-view-page.vue');
// const SellerImporterPage = () =>
//   import('@/modules/seller/components/seller-importer-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'distributor',
        path: '/distributor',
        component: DistributorListPage,
        meta: {
          auth: true,
          permission: Permissions.values.distributorView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.distributor') 
          next();
        },
      },
      // {
      //   name: 'seller',
      //   path: '/seller',
      //   component: SellerListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sellerView,
      //   },
      // },
      // {
      //   name: 'sellerNew',
      //   path: '/seller/new',
      //   component: SellerFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sellerCreate,
      //   },
      // },
      // {
      //   name: 'sellerEdit',
      //   path: '/seller/:id/edit',
      //   component: SellerFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sellerEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'sellerExport',
      //   path: '/seller/:id/export',
      //   component: SellerExportPage,
      //   meta: {
      //     auth: true,
      //     // permission: Permissions.values.sellerEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'sellerImporter',
      //   path: '/seller/import',
      //   component: SellerImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sellerImport,
      //   },
      // },
      // {
      //   name: 'sellerView',
      //   path: '/seller/:id',
      //   component: SellerViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.sellerView,
      //   },
      //   props: true,
      // },
    ],
  },
];
