import { RestoreService } from '@/modules/restore-items/restore-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    bills:[]
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    bills: (state) => state.bills
  },

  mutations: {
    SET_BILLS(state,payload){
      state.bills = payload
    },
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await RestoreService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/restore');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await RestoreService.create(values);
        // await RestoreService.incrementQuantityByFieldValue(values.itemType, values.itemId, 1)
        commit('CREATE_SUCCESS');
        // Message.success(i18n('entities.restore.create.success'));
        // routerAsync().push('/restore');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await RestoreService.update(id, values);
        // await RestoreService.decrementQuantityByFieldValue(values.itemType, values.itemId, 1)
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.restore.update.success'));
        // routerAsync().push('/restore');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async getBills({commit}){
      const data = await RestoreService.getBills()
      commit('SET_BILLS',data)
    },
    async doRestore({ commit }, { notes, values }) {
      try {
        commit('UPDATE_STARTED');
        await RestoreService.restoreItemsByTransaction(notes, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.restore.update.success'));
        routerAsync().push('/restore');
        // return true
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
        return false
      }
    },

    
  },
};
